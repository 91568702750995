import { Provenance } from '@tracr/shared-components'

import DarkTooltip from 'components/DarkToolTip'

const lockedToolipCopy = (lifecycle, provenance) => {
  const lifecycleCopy = lifecycle === 'polished' ? '' : 'polished and '

  return {
    title: provenance.is_country_code_available ? 'Single provenance' : 'Mixed provenance',
    description: `The provenance will be made available when the diamond is ${lifecycleCopy}unlocked.`,
  }
}

export const ProvenanceWithTooltip = ({ provenance, lifecycle, className }) => {
  const tooltip = provenance?.is_locked && lockedToolipCopy(lifecycle, provenance)

  return tooltip ? (
    <DarkTooltip title={tooltip.title} description={tooltip.description} titleMaxWidth={280}>
      <span>
        <Provenance provenance={provenance} className={className} />
      </span>
    </DarkTooltip>
  ) : (
    <Provenance provenance={provenance} className={className} />
  )
}
